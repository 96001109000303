.emphasis {
    color: #029CD7;
}

.events-calendar-center{
    padding-bottom: 25px;
}

#calendar-header{
    margin-top: -65px;
    height: 80vh;
}

.events-container {
    background-color: black !important;
    height: 100% !important;
    text-align: left !important;
}

/* Text is blocking (covering) the calendar. Bug? */
/* The class name "events-main-text" doesn't work. Changing the class name makes the code work */
.events-text {
    /* When height: 100%, the calendar buttons do not work. Changing it to use "vh" works.*/
    /* Background-image height and events-text height affect each other when one of them changed */
    height: 30vh;
    position: absolute;
    z-index: 2;
    font-family: Roboto;
    font-size: 3.5rem;
    color: white;
    font-weight: bold;
    line-height: 1.2;
    text-align: left;
    padding-top: 32vh;
    grid-template-rows: 1fr 1.4fr;
    padding-left: 3rem;
}
/* The class name "events-main-text" doesn't work. Changing the class name makes the code work */
.events-text p:first-of-type {
    grid-column: 1/2;
    grid-row:1/2;
    align-self: end;
}
/* The class name "events-main-text" doesn't work. Changing the class name makes the code work */
.events-text p:nth-of-type(2) {
    grid-row:2/3;
    grid-column: 1/2;
    align-self: start;
    margin-bottom: 4rem;
}

.events-text p {
    padding: 5px 20px 5px;
    border-radius: 10px;
    background-color: rgba(19, 19, 21, 0.7);
    font-size: 2.5rem;
}
/* When the class name is "events-main-background" and the CSS properties are changed, the background image size doesn't change at all. 
   Maybe a bug? However, after changing the class name, the changes will show. */

.background-image {
    /* Background-image height and events-text height affect each other when one of them is changed */
    height: 80vh;
    background: 
    linear-gradient(rgba(0, 0, 0, 0.40), rgb(0, 153, 255, 0.40)),
    url("./images/events-collage.jpg") no-repeat center !important;
    background-size: cover !important;
    align-self: center;
    filter: blur(1px);
    font-size: 4.4rem;
    color: white;
    opacity: .6;
    font-weight: bold;
    grid-row: 1/2;
    grid-column: 2/3;
}

.blue-line {
    width: 100%;
    height: 2px;
    background-color: #029CD7;
}

.acm-calendar {
    background-color: black;
    /* height: 100%;
    min-width: 100%; */
}
.large-screen-calendar{
    background-color: #2b2727; 
}

.small-screen-calendar {
    display: none;
    background-color: #2b2727; 
}

@media only screen and (max-width: 1004px){
    .jumbo-text{
        font-size: 2.1rem;
    }
    /* The class name "events-main-text" doesn't work. Changing the class name makes the code work */
    .events-text {
        padding-top: 30vh;
        padding-left: 0.5rem;
        font-size: 3.4rem;
    }    
    .large-screen-calendar {
        display: none;
    }
    .small-screen-calendar {
        display: block;
    }
}

@media only screen and (max-width: 420px){

    .current-events-header {
        width: 100%;
        font-size: 3.3rem;
        margin-left: 0 !important;
        text-align: center;
    }

    .upcoming-main {
        width: 100%;
        font-size: 3.3rem;
        margin-left: 0 !important;
        text-align: center;
    }

    .past-events-header {
        text-align: center;
        width: 100%;
        font-size: 3.3rem;
        margin-left: 0 !important;
    }
    .current-events-card {
        margin: 0 !important;
    }

    .jumbo {
        padding-left: 1rem !important;
    }
}
