/*--------------------------------------------------------------
# Main header, banner
--------------------------------------------------------------*/

.prodev-header-container {
  height: 80vh;
  color: white;
  font-weight: bold;
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgb(0, 153, 255, 0.4)),
    url("./images/programs-background.jpg") center center;
  background-size: cover !important;
}
#prodev-header-title {
  position: absolute;
  z-index: 1;
  font-size: 2.5rem;
  top: 30%;
  left: 10%;
}

/*--------------------------------------------------------------
# end of main header
--------------------------------------------------------------*/

.quote-style-1 {
  height: 30vh;
  width: 100%;
  z-index: 2;
  font-family: Roboto;
  font-size: 3.5rem;
  color: white;
  font-weight: bold;
  line-height: 1;
  text-align: left;
  padding-top: 16vh;
  /* display: grid; */
  grid-template-rows: 1fr 1.4fr;
  padding-left: 2rem;
}

/*Dropdown button*/
.item-dropdown {
  background-color: #464141;
}
#dropdown-button {
  align-self: center;
  margin-left: 3em;
  margin-bottom: -1em;
}

/*--------------------------------------------------------------
# Programs
--------------------------------------------------------------*/
.programs-tab-container {
  display: block;
  max-width: 100%;
}

.programs-card {
  margin: auto;
  text-align: left;
  max-width: 85%;
  background-color: #333030;
}

.programs-nav-link {
  color: white;
}

.programs-nav-link-tab {
  outline: 1px solid white;
}

.programs-tab-content {
  width: 100%;
  background-image: linear-gradient(#333030, #141415);
  border-radius: 2em;
  margin-top: 1.5em;
  padding: 10px;
}

.programs-images {
  min-width: 3in;
  max-width: 30vw;
  margin: 5px;
}
.programs-body {
  color: white;
}

.programs-tab-content .poster {
  margin-top: 1rem;
}

.program-description {
  margin-top: 1em;
  padding-top: 1em;
  font-size: 25px;
  text-align: center;
}

.aboutprogram {
  font-size: 17px;
}
/*--------------------------------------------------------------
# End of programs
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Disclaimer secion
--------------------------------------------------------------*/
.disclaimer-body {
  padding-left: 20px;
  text-align: center;
}

.disclaimer {
  font-size: 20px;
}

.disclaimer_header {
  font-size: 30px;
  text-align: center;
  color: red;
  padding-top: 10px;
}
/*--------------------------------------------------------------
# End of Disclaimer section
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# list of events or skills
--------------------------------------------------------------*/
.workshop-container {
  border-top: solid #333030;
  border-bottom: solid #333030;
  border-width: 0.5em;
  display: flex;
  max-width: 100%;
  align-items: center;
  margin: auto;
}
.skill-item,
.skill-item2 {
  text-align: center;
  padding: 25px 20px;
  transition: all ease-in-out 0.3s;
  border-radius: 2rem;
}

.skill-item2 {
  left: 17%;
}

.icon {
  margin: 0 auto;
  width: 50px;
  height: 50px;
  transition: all 0.3s ease-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transform-style: preserve-3d;
}

.icon-box {
  margin: 0 10px 10px;
  text-align: center;
  padding: 65px 20px;
  transition: all ease-in-out 0.3s;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 5);
  background-color: #0f5da7;
  border-radius: 2rem;
}
.icon-box:hover {
  background-color: #2678c5;
}
/*--------------------------------------------------------------
# End of skills section
--------------------------------------------------------------*/

.quote-bg {
  /* When the height is less than 70vh, the beginner and advanced tabs are not be clickable */
  height: 80vh;
  background: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)),
    url("images/programs-background.jpg");
  background-blend-mode: darken;
  background-size: cover !important;
  align-self: center;
  font-size: 4.4rem;
  color: white;
  opacity: 0.95;
  font-weight: bold;
  grid-row: 1/2;
  grid-column: 2/3;
}
.signup-section {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 100%;
  margin: auto;
}
.current-poster {
  max-width: 40em;
  padding: 1em;
}
.programs-poster {
  max-width: 30em;
}
.poster-content {
  margin-bottom: 5em;
}
.poster-content > h2 {
  font-size: x-large;
}

.programs-body {
  color: white;
}

.mentorship-body {
  padding-top: 10vh;
  padding-left: 10vw;
  padding-bottom: 8vh;
  padding-right: 10vw;
  text-align: left;
}

.anchor-blue {
  color: #029cd7 !important;
}

.mentorship-images {
  float: right;
  min-width: 1.75in;
  max-width: 20vw;
  margin: 15px;
}

iframe[src*="youtube"] {
  display: block;
  margin: 1rem auto;
  max-width: 100%;
}

.point {
  margin-left: 1rem;
}

.programs-tab-content .program-description {
  font-size: 25px;
  text-align: center;
}

.programs-tab-content .poster {
  margin-top: 1rem;
}

.join-button {
  border: 3px solid green;
}

.pro-dev-card {
  margin-left: 325px;
}



@media only screen and (max-width: 600px) {
  .prodev-header-container {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgb(0, 153, 255, 0.4)),
      url("./images/header-portrait-background-image.png") center center;
  }
  #prodev-header-title {
    position: absolute;
    z-index: 1;
    font-size: 2.5rem;
    top: 30%;
    left: 10%;
  }
  .skill-item2 {
    left: 0.2%;
  }
  .quote-style-1 {
    padding-top: 30px;
  }
  .programs-body {
    padding-top: 75px;
  }
  .disclaimer-body {
    padding-right: 5px;
  }
  .flex-column {
    padding-bottom: 35px;
  }
  .programs-poster {
    /* min-width can be used to change poster size on mobile view */
    min-width: 70vw;
    padding-left: 0px;
    padding-right: 0px;
  }
  .join-button {
    align-items: center;
  }
  .pro-dev-card {
    margin-left: -50px;
  }
  .programs-tab-label {
    font-size: 0.85rem;
  }
  iframe[src*="youtube"] {
    min-width: 90vw;
  }
}

/*Fixing icon misalignment*/
@media only screen and (max-width: 991px) {
  .skill-item2 {
    left: 0.1%;
  }
}
