.impAnnouncements-tease {
    // background-image: url('../impAnnouncements/images/announcements.jpg');
    background-color: #282A2E;
    width: 100%;
    // background-size: cover;
    // // background-position-y: 30%;
    // background-repeat: no-repeat;
    color: white;

    justify-content: center;
    display: flex;
    flex-wrap: wrap;

    .img {
        filter: grayscale(100%);
    }

    // Source: https://www.itsupportguides.com/knowledge-base/website-tips/how-to-make-embed-youtube-iframe-mobile-responsive/
    iframe[src*=youtube] {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        padding-bottom: 10px;
    }

    .content {
        text-align: center;
        vertical-align: middle;
        display: table-cell;
        height: 100%;

        .iconContainer{
            display: flex;
            justify-content: center;
            gap: 75px;
            
        }
        .iconSocial:hover{
            color: #029cd7 !important;
            cursor: pointer;
            transition: all 0.3s;
        }
        

        .subTitle {
            font-size: 2rem;
            line-height: 1;
            font-weight: lighter;
        }

        .title{
            padding-top: 10px;
            margin-bottom: 2rem;
            font-size: 3.5rem;
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
            line-height: 1;
            text-decoration: underline;
        }
        
        .description {
            text-align: center;
            list-style-position: inside;
            font-weight: lighter;
            font-size: 2rem;
            margin: auto;
            margin-top: 0.2rem;
            padding-bottom: 1em;
            width:80%;
        }

        .description-header{
            text-align: center;
            list-style-position: inside;
            font-weight: lighter;
            font-size: 2rem;
            margin-top: 1rem;
            margin-bottom: -2rem;
        }

        .space {
            margin-bottom: 2rem;
        }

        li {
            padding-top: .5rem;
            padding-bottom: .5rem;
        }

        a:link {
            background-color: white;
            text-decoration: underline;
        }
    }
}

.upcoming-title, .featured-title{
    padding-top: 10px;
    margin-bottom: 2rem;
    font-size: 3.5rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    line-height: 1;
    text-decoration: underline;
}


.event-description {
    font-weight: lighter;
    font-size: 2rem;
}

.flyer-container{
    width:22rem !important;
    margin:auto;
}

#event-container{
    border-radius: 4rem;
    margin:2em 0 2em;
}

#upcoming-flyer{
    border-right: 3px dashed rgb(196, 194, 194);
}

.card-row {
    display: flex;
    border: 5px solid rgb(50, 97, 123);
    background-color: rgba(0, 0, 0, 0.215);
    border-radius: 3rem;
    width: 1350px;
    height: 830px;
    margin-left: 15px;
    margin-bottom: 20px;
    margin-right: 20px;
}

.card-1 {
    margin-top: 40px;
    margin-left: -440px;
    margin-right: -800px;
    width: 1120px;
    height: 760px;
    border-right: 5px dashed rgb(50, 97, 123);
}

.card-2 {
    margin-top: 40px;
    margin-left: 400px;
}

.video-header{
    border: 5px solid rgb(137, 136, 136);
    background-color: rgb(0, 0, 0);
    padding: 16px 16px 12px 16px;
    border-radius: 3rem;
    width: fit-content;
    margin: auto;
}

.event-section {
    margin: auto;
    width: fit-content;
    height: 760px;
}

.modal-content {
    background-color: black !important;
    color: white;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
    .impAnnouncements-tease {


        .content{
        
            .title{
                font-size: 1.75rem;
            }

            .description {
                font-size: 1.4rem;
            }
            
        }
        

        .description {
            font-size: 1.4rem;
        }
    }

    .upcoming-title, .featured-title{
        font-size: 1.75rem;
    }
    .event-description {
        font-size: 1rem;
    }
    #upcoming-flyer{
        border-right: 0px dashed rgb(50, 97, 123);
    }

    .card-row {
        display: block;
        margin-left: 40px;
        border: 0px solid rgb(50, 97, 123);
        background-color: rgba(0, 0, 0, 0);
        width: 0px;
        height: 1300px;
        margin-left: 15px;
        margin-bottom: 40px;
        margin-right: 20px;
    }
    
    .card-1 {
        margin-right: -750px;
        margin-left: 2px;
    }

    .card-2 {
        margin-left: 5px;
        margin-top: -70px;
    }
    
    /*border hidden*/
    .video-header {
        border: 0px solid rgba(137, 136, 136, 0);
        background-color: rgba(0, 0, 0, 0);
        padding: 16px 16px 12px 16px;
        border-radius: 3rem;
        width: fit-content;
        margin: auto;
    }

    .event-section {
        margin: auto;
        width: fit-content;
        height: 760px;
    }

}


@media screen and (min-width: 768px) and (max-width: 1280px) {
    .impAnnouncements-tease {



        .content{
        
            .title{
                font-size: 1.75rem;
            }

            .description {
                font-size: 1.4rem;
            }
            
        }
    }

    .upcoming-title, .featured-title{
        font-size: 1.75rem;
    }
    .event-description {
        font-size: 1.4rem;
    }

    .card-row {
        display: block;
        margin-left: 40px;
        border: 0px solid rgb(50, 97, 123);
        background-color: rgba(0, 0, 0, 0);
        width: 0px;
        height: 1300px;
        margin-left: -75px;
        margin-bottom: 120px;
        margin-right: 20px;
    }
    
    .card-1 {
        margin-right: -750px;
        margin-left: 2px;
    }

    .card-2 {
        margin-left: 5px;
        margin-top: -50px;
    }

    /*border hidden*/
    .video-header {
        border: 0px solid rgba(137, 136, 136, 0);
        background-color: rgba(0, 0, 0, 0);
        padding: 16px 16px 12px 16px;
        border-radius: 3rem;
        width: fit-content;
        margin: auto;
    }

    .event-section {
        margin: auto;
        width: fit-content;
        height: 760px;
    }
    
}