.membership-card {
	height: 80vh;
	width: 100%;
	background: 
	linear-gradient(rgba(0, 0, 0, 0.40), rgb(0, 153, 255, 0.40)),
	url("../aboutus/images/fall-2019-general-meeting.jpg");
	background-size: cover !important;
	background-attachment: fixed;
	align-self: center;
	filter: blur(1px);
	font-size: 4.4rem;
	color: white;
	opacity: .6;
	font-weight: bold;
	grid-row: 1/2;
	grid-column: 2/3;
  }

.membership-text {
	height: 30vh;
	width: 100%;
	position: absolute;
	z-index: 2;
	font-size: 3.5rem;
	color: white;
	font-weight: bold;
	line-height: 0.9;
	text-align: left;
	padding-top: 30vh;
  
	/* display: grid; */
	grid-template-rows: 1fr 1.4fr;
	padding-left: 3rem;
}

.headerLogo{
	width: 500px;
}
.header-text{
  font-size: 30px;
  font-family: 'Open Sans', sans-serif !important;
}
.iconBrand{
  margin-right: 10px;
}

.carousel_container{
	width: 800px;
	height: 400px;
	margin-top: 2rem;
	margin-bottom: 2rem;
}

@media (max-width: 860px){
	.carousel_container{
		width: 100%;
		height: 400px;
	}

	.membership_form{
		width: auto
	}

	.member-sections {
		padding-top: 10px;
		display: block !important;
		margin: auto !important;
		padding-bottom: 50px;
	}

	.mentorship-section, .pro-dev-section, .project-section, .community-section, .newsletter-section {
		margin: auto !important;
		border: 5px solid white;
		border-radius: 3rem;
		padding: 30px;
		width: auto;
	}

	.membership-text {
		margin-left: -30px;
	}

	.images {
		margin: auto;
		padding-top: 20px;
		text-align: center;
		display: flex;
	}
}

.image{
	/* height: 50px; */
	width: 100px;
}

.slide{
	width: 100vw;
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

#logoImage{
	width: 300px;

}

.membership-shirt{
	width: 0px;
}

.paypal-form-container {
	margin-top: 1rem;
}

.shirt-image {
	padding-top: 1em;
}

#membership-body{
	background-color: #161616;
	text-align: left;
}

.membership-registration{
	width: 10px;
}

.tab-content{
	width: 55vw;
}

.tab-container{
	width: 9rem;
}

.mem-card{
	width: auto;
	height: auto;
	background-color:#00000000 !important;
	/* padding: 20px;
	margin: 20px; */
}

.form_card{
	background-color: teal !important;
}

.nav-item{
	color:white ;

}

.header-left{
	float: left;
}

.btn-info{
	color:white !important;
}

.t-shirts{
	width: 150px;
}

.membership-footer-text{
 text-align: center !important;
}

.venmo-container {
	display: flex;
	width: 100%;
	height: 22rem;
	margin-top:1rem;
	margin-bottom:1rem;
}

.venmo-qr {
	width: 14rem;
	height: 22rem;
}

h2{
	padding-top: 10px;
}

.email{
	text-decoration: underline;
	text-decoration-color: #ffffff !important;
	color: white !important;
}

.accordion-background{
	background-color: #161616;
}

.text-info{
	color: white !important;
	text-align: center;
}

.member-description{
	font-size: 1.5rem;
	padding: 8px;
	background-color: black;
}

.content-box{
	margin-top: 20px;
	margin-bottom: 60px;
	margin-left: 10px;
	margin-right: 10px;
}

.member-description-points{
	font-size: 20px;
	margin: 10px;
}

.member-description-points a{
	text-decoration: underline;
}

.membership-description-section{
	display: inline-block;
	vertical-align: top;
	/* width: 60%;
	height: 60%; */
	margin: 20px; 
	background-color: #494949;
}

.list-group{
	margin-bottom: 1.5rem;
}

.list-points{
	background-color: #424242;
	font-size: 0.9rem;
	font-weight: bold;
	margin: auto;
	padding: 20px;
	width: 95%;
	border-radius: 6px;
}

.refund{
	color: red;
  font-family: 'Libre Caslon Text', serif;
	font-weight: 600;
	font-size: 1.3rem;
	margin: 16px;
}

.additional-fee{
	font-family: 'Libre Caslon Text', serif;
	font-style: italic;
	margin: 12px;
}

.success_message{
	margin-top: 100px;
}

/* .join-card:hover {
	cursor: pointer;
} */

.member-card-header {
	background-color: black;
	border: 3px solid rgb(34, 88, 154);
	border-radius: 3rem;
	margin-bottom: 20px;
}

.member-body {
	border: 3px solid rgb(34, 88, 154);
	background-color: black;
	border-radius: 3rem;
	margin-bottom: -15px;
}

.member-sections {
	display: inline-flex;
	margin: auto;
	padding-top: 30px;
	padding-bottom: 20px;
}

.info-header {
	font-size: 25px;
	font-weight: 600;
	padding-bottom: 10px;
	border-bottom: 5px solid rgb(34, 88, 154);
}

.mentorship-section, .pro-dev-section, .project-section, .community-section, .newsletter-section {
	margin: auto !important;
	border: 5px solid white;
	border-radius: 3rem;
	padding: 20px;
	margin-top: 5px !important;
	margin-bottom: 20px !important;
	margin-right: 15px !important;
	width: auto;
}

.register-info {
	font-size: 25px;
	font-weight: 600;
	border-top: 5px solid rgb(34, 88, 154);
	padding-top: 10px;
}

.register-body {
	font-size: 20px;
	font-weight: 600;
}

.images {
	margin: auto;
	padding-top: 20px;
	text-align: center;
	display: block;
}

.images img {
	border: 5px solid white;
}