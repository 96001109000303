.AboutUs-tease {
    // background-image: url('../aboutus/images/FirstGeneralMeetingFall2022.jpg');
    background-color: #161616;
    width: 100%;
    
    background-size: 100%;
    // background-position-y: 90%;
    // background-repeat: no-repeat;
    color: white;

    justify-content: center;
    display: flex;
    flex-wrap: wrap;

    .content{
        
        #blue-rectangle{
            background-color: #1C7FDA;
            width: 6rem;
            height: 0.35rem;
            margin: 70px auto 35px auto;
            
        }
    

        .who-are-we-content{
            margin: auto;
            .title{
                text-align: center;
                font-size: 3rem;
                font-weight: bold;
                text-transform: uppercase;
                
            }

            .description{
                width: 50%;
                text-align: center;
                margin: 5rem auto 3rem auto;
                font-size: 1.5rem;
                
                
            }
            
            
        }

        .link-btn{
            margin: 40px auto 50px auto;
            .label {
                font-size: 20px;
                padding: 1rem;
                color: white;
                background-color: #0A84FF;
                transition: all .5s;
                
            }

            .label:hover{
                background-color: white;
                color: #0A84FF;
            }
        }
        
        
    }
}

@media screen and (max-width: 550px) {
    .AboutUs-tease {
        .content{
        
            .who-are-we-content{
                .title{
                    font-size: 2rem;
                }

                .description {
                    width: 80%;
                    font-size: 1.2rem;
                    
                }
            }

            .link-btn{
                
                .label{
                    font-size: 16px;
                    
                }
            }
        }
    }
    
    
}

// @media screen and (max-width: 1200px) {

//     .AboutUs-tease {

//         background-size: 350%;
//         background-position: center;

//         .right {
//             display: none;
//         }
    
//         .left {
//             width: 100%;
    
//             .content {
//                 padding: 2rem;
//                 width: inherit;


//                 .subTitle {
//                     font-size: 1.5rem;
//                 }

//                 .title {
//                     font-size: 3rem;
//                 }
//                 }

//         }
//     }

//   } 

//   @media only screen and (max-width: 600px) {
//     .AboutUs-tease {
//         height: 100%;
//         background-size: cover;
//   }
// }

// Was inside AboutUs-tease selector 
    //.left {

    //     width: 50%;
    //     height: 100%;
    //     display: table;
        
    //     .content { 
            
    //         width: 51rem;
    //         padding: 8rem;
    //         display: table-cell;
    //         vertical-align: middle;

    //         .subTitle {
    //             font-size: 2rem;
    //             line-height: 1;
    //             font-weight: lighter;
    //         }

    //         .title{
    //             font-weight: bold;
    //             font-size: 4rem;
    //             text-transform: uppercase;
    //             line-height: 1;
    //         }
    
    //         .link-btn{
    //             margin-top: 1rem;

    //             .label {
    //                 padding: 1rem;
    //                 color: white;
    //                 background-color: #0A84FF;
    //             }

    //             .label:hover{
    //                 background-color: white;
    //                 color: #0A84FF;
    //             }
    //         }
    //     }
    // }

    // .right{

    //     width: 50%;
    // }