
.row-0{
    display: flex;
}
.row-1{
    display: flex;
}
.row-2{
    display: flex;
}
.row-3{
    display: flex;
}
.row-4{
    display: flex;
}
.row-5{
    display: flex;
}

.cell {
    background-color: #282a2e;
    overflow-y: auto;
    color: white;
    height: 9rem;
    width: 100%;
    border: 1px solid rgb(116, 116, 116, 0.6);
    /* padding-top: .5rem; */
}
.empty {
    background-color: #1c1d20;
}
.event{
    background-color: rgb(58, 60, 77);
}

.day-number {  
    text-align: end;
    justify-self: end;
    margin-top: .4rem;
    margin-right: .8rem;
}

.day-header-container {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.day-header {
    border: 0.2px solid rgb(116, 116, 116, 0.6);
    color: #029CD7;
    text-align: center;
    font-size: 1.4rem;
    font-weight: bold;
}

.test2 {
    display: none;
}

.calendar-header {
    display: flex;
    font-weight: 500;
    margin-left: 130px;
}

.prev-button {
    transform: scaleX(-1);
}

.prev-button, .next-button {
    filter: invert(100%);
    margin-top: 3.5px;
}

.next-button {
    margin-left: -20px;
}

.month-header, .before-header, .after-header {
    font-size: 25px;
    font-weight: 400;
    margin-left: 35px;
    margin-right: 60px;
}

.today {
    background-color:#4f5a6e;
    color: white;
}

.today p:hover {
    color: rgb(194, 194, 194);   
}


/****************************************************
// Events section
*****************************************************/
.events-section{
    display: flex;
    flex-direction: column;
    border: solid #b4abab;
    border-width: 2px;
    margin: 20px 0 20px;
    text-align: center;
    font-size: larger;
    background-color: rgb(36, 37, 48);
    height: 85%;
}
.upcoming-container{
    background-color: #2b2727;
    max-height: fit-content;
    margin-left: -20px;
}
.events-container h3{
    margin-top: 1em;
    text-align: center;
}
.event-block{
    display: flex;
    flex-direction: column;
    flex:1;
    justify-content: space-evenly;
    padding: 1em;
    margin: 0 1em 0;
}
.event-block:hover {
    color: rgb(0, 132, 255);
    cursor: pointer;
}
.event2{
    border-top: solid #b4abab;
    border-width: 2px;
}
/****************************************************
// End of events section
*****************************************************/

@media only screen and (max-width: 1004px){
    .day-header {
        font-size: 1rem;
    }
}

@media only screen and (max-width: 420px){
    .day-header {
        font-size: 1rem;
    }

    .test1{
        display: none;
    }

    .test2 {
        display: inline;
    }

    .month-header, .before-header, .after-header {
        font-size: 15px;
        font-weight: 400;
        margin-right: 5px;
        margin-left: 20px;
    }

    .calendar-header {
        display: flex;
        font-weight: 500;
        margin-left: -15px;
    }
}


@media only screen and (max-width: 990px){
    .upcoming-container{
        background-color: #2b2727;
        max-height: fit-content;
        margin: 5px;
    }
}