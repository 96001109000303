.board-header-container {
  height: 80vh;
  color: white;
  font-weight: bold;
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgb(0, 153, 255, 0.4)),
    url("./img/Fall-2024-Board.jpg") ;
  background-size: cover  !important;
  background-position-y: top 25%;
  
}

#board-header-title {
  position: absolute;
  z-index: 1;
  font-size: 2.5rem;
  top: 30%;
  left: 5%;
}

.body {
  width: 100vw;
  background-color: #1f1f1f !important;
  align-items: center;
  font-family: "Open Sans", sans-serif !important;
}
.subcon-body {
  text-align: left;
  width: 100vw;
  padding: 25px;
}

.container-body {
  width: 100vw;
  color: white;
  font-family: "Montserrat", sans-serif;
  padding: 0px !important;
  margin-left: 0px !important;
}
.row {
  width: 100vw;
  padding: 0px !important;
}

.small-header {
  text-transform: uppercase;
  font-size: 15px;
  padding: 0px;
  text-align: left;
}

.col-leaders {
  display: inline-flex;
}

.view-more {
  float: right;
  color: white;
}

.view-more-sm {
  float: left;
  color: white;
}

a:hover {
  color: #029cd7 !important;
  text-decoration: none;
}

.background-change {
  background-color: #1f1f1f;
}
.full-container-leaders {
  margin: 100px;
}
.center-officers {
  width: 100vw;
}
.officer {
  padding-bottom: 25px;
}

.board {
  width: auto;
  height: 150px;
}

.leaders-div {
  background-color: #121212 !important;
}

.image-cropper {
  width: 100px;
  height: 100px;
  top: 1rem;
  left: 1rem;
  object-fit: cover;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  border: solid 2px #1b1d36;
}

.select-year-text {
  padding-left: 40px;
}

.select-year-tab {
  align-items: center;
}

.profile-pic {
  display: inline;
  margin: 0 auto;
}

.officer-info {
  width: 350px;
  padding-left: 25px;
}

.h2-black {
  color: black;
}

.hr-header {
  color: #029cd7;
}

.leaders-div {
  /* clip-path: polygon(0 0, 100% 7%, 100% 100%, 0% 100%); */
  padding-top: 30px !important;
}

.centered-leader {
  text-align: center;
}

h2 {
  margin-left: auto;
  margin-right: auto;
  font-size: 23px;
}

.h2-leaders {
  padding: 10px;
  font-size: 2.2rem;
}

.officer-info {
  display: inline-block;
  text-align: left;
  position: relative;
  top: 15px;
  left: 5px;
}

.name {
  flex-wrap: wrap;
  word-wrap: normal;
}

.leader-header {
  width: 30%;
  padding: 10px;
  text-align: center;
  font-size: 2.5rem;
  border-top: 1px solid #029cd7;
  border-bottom: 1px solid #029cd7;
}

.board-leader-winner {
  width: 100px;
  height: 100px;
  top: 1rem;
  left: 1rem;
  object-fit: cover;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  border: solid 2px #1b1d36;
}

.board-leader-profile {
  display: inline;
  margin: 0 1em 1em;
}
.image-contain {
  width: 95vw;
}

.leader-info {
  text-align: center;
  font-size: 2.2rem;
}
.leader-position {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 4rem;
  font-size: 35px;
  text-align: center;
}

.leader-background {
  width: 29rem;
  height: 135px;
  background: rgb(50, 97, 123);
  border: 3px solid #000000;
  box-sizing: border-box;
  border-radius: 20px;
}

.linkin {
  margin-right: 10px;
  margin-top: 10px;
}

.email {
  margin-top: 5px;
  margin-right: 10px;
}

.git {
  margin-top: 5px;
  margin-right: 10px;
}


/************New board style*************************************/
.boardcontainer {
  margin: auto !important;
  border: 2px solid rgb(0, 232, 248);
  background-image: url("./img/bluebackdrop.jpg");
  background-size: cover;
}

.leader-of-the-month {
  color: white;
  width: 98vw;
  margin: 2em auto 2em;
}

.groupheader {
  text-align: center;
  font-size: xx-large;
}

.leadercontainer {
  min-width: 250px;
  max-width: 250px;
  height: 300px;
  float: none;
  margin: 1.2em auto 1.2em;
  border: 2px solid rgb(0, 232, 248);
  background-color: #0256a5 !important;
}
.role {
  color: #c5c5c5 !important;
}
.leaderimg {
  object-fit: cover;
  max-width: 150px;
  max-height: 150px;
  margin: 0px !important;
  filter: blur(0.3px);
}

.bottomheader{
  border: 1px solid rgb(0, 232, 248);
  width: 25em;
  margin-bottom: 2em;
}

@media only screen and (max-width: 430px) {
  .leadercontainer {
    min-width: 100px;
    height: 200px;
  }
  .leaderimg {
    max-width: 100px;
    max-height: 100px;
  }
}

/************end new board style*********************************/

/************Board tab styles*************************************/
.board-tab-container{
  background-color: #09477a;
  padding: 1em;
}


@media only screen and (max-width: 600px) {
  #board-header-title {
    position: absolute;
    z-index: 1;
    font-size: 2.5rem;
    top: 30%;
    left: 10%;
  }
  h2 {
    margin-left: auto;
    margin-right: auto;
    font-size: 4vw;
  }
  .h2-leaders {
    font-size: 5.5vw;
  }
  .select-year-tab {
    margin-left: 85px;
  }
  .select-year-text {
    margin-left: 5px;
  }
  .officer-info {
    display: inline-block;
    text-align: left;
    font-size: 3.5vw;
  }
  .all-row-leaders {
    margin-bottom: 2rem;
  }
  .board {
    width: auto;
    height: 12px;
  }
  .board-text {
    margin-top: -120px;
  }
  .leader-header {
    width: 90vw;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 2.2rem;
    border-top: 1px solid #029cd7;
    border-bottom: 1px solid #029cd7;
  }
  .board-leader-winner {
    text-align: center;
  }
  .leader-info {
    font-size: 2.2rem;
    text-align: center;
  }
  .leader-background {
    width: 100vw;
    height: 135px;
    background: #31355f;
    box-sizing: border-box;
    border-radius: 20px;
  }
  .image-cropper {
    width: 100px;
    height: 100px;
  }
}
