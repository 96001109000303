#introduction{
  width: 100%;
  height: 100vh;
  background: 
  linear-gradient(
  rgba(0, 0, 0, 0.55), 
  rgba(0, 0, 0, 0.55)),
  url("sideshow1.png");
  object-fit: cover;
  background-attachment: fixed;
}

#content{
  margin: 6em 12em 0;
}

#codeText{
  color: white;
	font-size: 2.5em;
  font-family: 'Open Sans', sans-serif !important;
}

@media screen and (max-width: 1300px) {
  #introduction {
    display: block;
    min-height: 60vh;
    background-attachment: local;
  }
  #content{
    margin: 3em 6em 0;
  }
  #codeText{
    display: block;
  }
} 

@media screen and (max-width: 750px) {
  #content{
    margin:auto;
    display: flex;
    padding-top: 10vh;
    width: 100%;
    justify-content: center;
  }
  #logoImage{
    width: 100%;
  }
  #codeText{
    display: none;
  }
} 