/* .about-us-card {
  height: 80vh;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgb(0, 153, 255, 0.4)),
    url("./images/Fall2022FirstGeneralMeeting.jpg") center;
  background-size: cover !important;
  background-attachment: fixed !important;
  background-position: center;
  align-self: center;
  filter: blur(1px);

  color: white;
  opacity: 0.6;
  font-weight: bold;
  grid-row: 1/2;
  grid-column: 2/3;
} */

.aboutus-header-container {
  height: 80vh;
  color: white;
  font-weight: bold;
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgb(0, 153, 255, 0.4)),
    url("./images/AboutUsFall2024.jpg") center center;
  background-size: cover !important;
}

#aboutus-header-title {
  position: absolute;
  z-index: 1;
  font-size: 3rem;
  top: 30%;
  left: 10%;
}

#blur-effect {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(1px);
}
#header-text {
  position: absolute;
  z-index: 2;
  font-size: 5rem;
  color: white;
  font-weight: bold;
  line-height: 1.2;
  top: 18%;
  left: 24%;
  transform: translate(-50%, -50%);

  text-align: left;
  padding-top: 32vh;
}
.about-card{
  background-color: #1f1f1f;
}

.aboutme-card{
  background-color: #1f1f1f;
}

.header-container {
  position: relative;
}

/* Don't delete: .jumbotron affects all Jumbotrons */
.jumbotron {
  color: white;
  padding-left: 3rem;
  margin-bottom: 0 !important;
  background-color: #1f1f1f !important;
}

.mission-statement-card {
  height: 86vh;
}

.our-mission-header {
  text-align: center;
  font-size: 3rem;
  color: #01a4ff;
  width: 75%;
  margin: auto;
}

.mission-statement-text {
  text-align: center;
  color: white;
}

.mission-statement-points {
  border-style: none;
  text-align: center;
  font-size: 1.75rem;

  width: 75%;
  margin: auto;
}

.mission-statement-item {
  background-color: #1f1f1f;
}

.carousel-leaders {
  width: 75%;
  margin: auto;
}

.carousel-leadergroup-image {
  max-height: 75rem;
  max-width: 50rem;
}

.bottom-section {
  background-color: #282a2e;
}

#about-us-info {
  padding: 64px 0;
}

.accordian-container {
  background-color: #282a2e;
}

.info-content-container {
  align-items: center;
  justify-content: center;
}

.button-row {
  width: 83.5%;
  margin-top: 60px;
  margin-left: 60px;
  margin-bottom: 30px;
}

.about-text {
  text-align: center;
  list-style: none;
  font-size: 1.25.25rem;
  margin: 10vw;
  color: white;
}

.about-item {
  margin: 2em;
}

.info-row {
  background-color: #1f1f1f;
  margin-left: -70px !important;
  padding: 0;
}

.info-row .about-text li {
  margin-bottom: 0.75rem;
}

.info-col {
  padding: 0;
  margin: auto;
  justify-content: center;
}

.offer-text {
  list-style: none;
  text-align: center;
  font-size: 1.25rem;
  margin: 10vw;
  color: white;
}

.offer-item {
  margin: 2em;
}

.info-row .offer-text li {
  margin-bottom: 0.75rem;
}

.join-text {
  list-style: none;
  text-align: center;
  vertical-align: center;
  font-size: 1.25rem;
  margin: 11vw;
  color: white;
}

.join-item {
  margin: 2em;
}

.constitution-text {
  list-style: none;
  text-align: center;
  font-size: 1.25rem;
  margin: 13vw;
  color: white;
}

.constitution-item {
  margin: 2em;
}

.info-row .join-text li {
  margin-bottom: 0.75rem;
}
.info-row .join-text p {
  margin-bottom: 0.75rem;
}

.about-us-image {
  padding: 1em;
  margin: auto;
  scale: 75%;
}

.accordian-header-container {
  width: fit-content;
  margin: auto;
  display: flex;
  justify-content: center;
}

.accordian-header {
  color: white;
  font-size: 2.5rem;
  width: 10em;
  text-align: center;
  margin-left: -10px;
}

.accordian-header:hover {
  color: #01a4ff;
  cursor: pointer;
}

/* Test */

@media screen and (max-width: 800px) {
  .bottom-section {
    margin-left: 15px;
  }

  .accordian-header {
    color: white;
    font-size: 1.75rem;
    width: 10em;
    text-align: center;
    margin-left: -10px;
  }

  .about-image {
    height: auto;
    width: auto;
  }

  .mission-statement-points {
    text-align: center;
    font-size: 1.25rem;
    color: white;
    width: 90%;
    margin: auto;
  }

  .our-mission-header {
    text-align: center;
    font-size: 3rem;
    color: white;
    width: 90%;
    margin: auto;
    background-color: black;
    border-radius: 0.5rem;
  }

  .aboutus-header-container {
    height: 80vh;
    color: white;
    font-weight: bold;
    position: relative;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgb(0, 153, 255, 0.4)),
      url("./images/header-potrait-background-image.png") center center;
    background-size: cover !important;
  }
  #aboutus-header-title {
    position: absolute;
    z-index: 1;
    font-size: 3.5rem;
    top: 30%;
    left: 10%;
  }

  #blur-effect {
    display: none;
  }
}

/* Current */
/* @media screen and (max-width: 800px) {
  .bottom-section {
    margin-left: 15px;
  }

  .accordian-header {
    color: white;
    font-size: 1.75rem;
    width: 10em;
    text-align: center;
    margin-left: -10px;
  }

  .about-image {
    height: auto;
    width: auto;
  }

  .mission-statement-points {
    text-align: center;
    font-size: 1.25rem;
    color: white;
    width: 90%;
    margin: auto;
  }

  .our-mission-header {
    text-align: center;
    font-size: 3rem;
    color: white;
    width: 90%;
    margin: auto;
    background-color: black;
    border-radius: 0.5rem;
  }

  .header-container {
    position: relative;
  }
  .about-us-card {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgb(0, 153, 255, 0.4)),
      url("./images/header-potrait-background-image.png") right;
    background-position: right;
  }
  #header-text {
    position: absolute;
    left: 38%;
    transform: translate(-50%, -50%);
    font-size: 3.5rem;
  }
} */
