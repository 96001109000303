 .link a{
    color: #007bff;
    text-decoration: none;
    cursor: pointer;
    font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    text-decoration: none;


 }


 .figureContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
 }

    .figureContainer img{
        width: 30%;
        height: 30%;
        border : 2px solid rgb(0, 153, 255, 0.4);
        margin: 20px;
    }

   .title{
      font-weight: bold;
      font-size: 60px;
      font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif

   }

   .card{
      border: 2px solid rgb(0, 153, 255, 0.4);
      border-radius: 8px;
   }

   .link img{
      width:5%;
      height: 5%;
   }