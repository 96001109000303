.navbar {
  position: fixed;
  background-color: #1c1c1e !important;
  top: 0;

  width: 100vw;
  padding-bottom: 10px;
  border-radius: 0 !important;
  letter-spacing: 2px;
  // border-bottom: 3px solid rgb(0, 153, 255, 0.4);
  // border-bottom: 3px solid rgb(0, 153, 255, 0.4);
  z-index: 102;
  transition: all 0.3s ease-in-out 0s;
  font-size: 2rem;
  -moz-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  text-align: left;
  vertical-align: center;
}

.hover-announcements {
  position: fixed;
  width: auto;
  color: white;
  font-size: 22px;
  white-space: nowrap;
  margin-left: 100%;
  margin-top: 75px;
  animation: floatText 25s linear infinite;
  z-index: 100;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.hover-announcements-background {
  background-color: #1c1c1e;
  position: fixed;
  z-index: 100;
  border-bottom: 3px solid rgb(0, 200, 255, 0.4);
  width: 100vw;
  height: 22px;
  padding-top: 115px;
}

// .hover-announcements:hover {
//     animation-play-state: paused;
// }

@keyframes floatText {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-205%, 0);
  }
}

.brandlogos {
  padding-top: 25px;
}
.return {
  margin-top: 10px;
}

.iconBrand {
  color: white;
}

.Navbar.solid {
  background-color: #1f1f1f !important;
  box-shadow: 0 0 4px #15161ace;
}

.disappear {
  display: none !important;
}

.navbar a {
  color: #ffffff !important;
  font-size: 16pt;
  margin-right: 15px;
}

.nav-membership-button {
  width: 100%;
  justify-content: center;
  font-size: 16px;
  margin-right: -15px;
}



.nav-item {
  padding-right: 1rem;
}

.btn {
  width: 10rem;
}

.nav-link:hover {
  color: #029cd7 !important;
  transition: all 0.3s;
}

.nav-item button:hover {
  background-color: #029cd7 !important;
  color: #ffffff !important;
  border-color: #2ecc71 !important;
}

// .mr-auto {
//   justify-content: center !important;
// }

#logo {
  width: 75px;
}

.nav-dropdown-text {

  color: black;
}

.navbar-toggler {
  border: none;
}

.collapsible-nav-dropdown {
  padding: 0;
}

.socials {
  margin-right: -5px;
  justify-content: center;
}

.election {
  margin-top: -10px;
  justify-content: center;
}

.iconHoverNav {
  display: inline-table;
  cursor: pointer;
  border-radius: 10px;
}

.iconHoverNav:hover {
  background: #636363;
  width: 4%;
  padding-top: 3px;
  padding-bottom: 3px;
}

.iconBrandNav {
  margin-right: 4px;
}

@media only screen and (max-width: 900px) {
  .navbar {
    background-color: #1c1c1e !important;
  }
  #homepage {
    display: block !important;
  }
  .socials {
    display: block;
    width: fit-content;
    margin-left: 1px;
  }
  .membership-btn {
    margin-left: -15px;

  }

  .hover-announcements {
    position: fixed;
    width: auto;
    color: white;
    font-size: 22px;
    white-space: nowrap;
    margin-left: 100%;
    margin-top: 70px;
    animation: floatText 20s linear infinite;
    z-index: 101;
  }

  .mr-auto {
    margin-top: 40px;
  }
  @keyframes floatText {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-125%, 0);
    }
  }
}
