.project-header-container {
  height: 80vh;
  color: white;
  font-weight: bold;
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgb(0, 153, 255, 0.4)),
    url("./img/projectWorkshopFall2024.jpg") center center;
  background-size: cover !important;
}
#project-header-title {
  position: absolute;
  z-index: 1;
  font-size: 2.5rem;
  top: 30%;
  left: 10%;
}

.projectheader {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(3, 55, 90, 0.5)),
    url("./img/bluecard.jpg");
  border: 1px solid white;
  width: 70%;
  margin: auto;
  margin-top: 1em;
  padding: 1em;
  border-radius: 2em;
  color: rgb(255, 255, 255);
  font-style: italic;
}

.emphasis {
  color: #029cd7;
}

.semester-bar {
  margin-bottom: 10px;
}

.project-card {
  text-align: left;
  color: white;
  padding-top: 30px;
}

#proj-contents {
  width: 100%;
}
.project-nav-link {
  color: white;
}

.project-nav-link-tab {
  outline: 1px solid black;
  margin-left: 25px;
  margin-right: -15px;
}

.project-tab-content {
  width: 100%;
  background-image: linear-gradient(#333030, #141415);
  border-radius: 3rem;
  padding: 10px;
}

.border-carousel {
  margin-top: 25px;
  border: 5px solid rgb(50, 97, 123);
  border-width: 4px;
}

.mentor-carousel {
  margin-bottom: 15px;
}

.project-images {
  min-width: 2in;
  max-width: 20vw;
  margin: 5px;
}

.project-submit {
  margin-left: 15px;
}

.project-poster {
  min-width: 300px;
  max-width: 30vw;
}

.project-carousel {
  max-width: 200px;
}

.project-body {
  color: white;
}

.disclaimer-body {
  margin-top: 10px;
  padding-left: 20px;
  text-align: left;
  padding-bottom: 40px;
  margin-bottom: 3em;
  background-color: #141415;
  border-radius: 2rem;
  margin-left: 50px;
  margin-right: 50px;
}

.disclaimer_header {
  font-size: 30px;
  text-align: center;
  text-decoration-color: #fff;
  color: rgb(255, 0, 0);
  padding-top: 10px;
}

.date-disclaimer {
  color: rgb(0, 217, 255);
}

.disclaimer_info {
  padding: 20px;
}

.space {
  margin-top: 1.5rem;
}

.videoWrapper {
  position: relative;
  padding-bottom: 20px;
  padding-left: 30%;
  padding-right: 30%;
  /*max-width: 90%;*/
}

/* Source: https://www.itsupportguides.com/knowledge-base/website-tips/how-to-make-embed-youtube-iframe-mobile-responsive/ */
iframe[src*="youtube"] {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.lower-margin {
  margin-bottom: 2.7rem;
}
.upper-margin {
  margin-top: 1.6rem;
}

.disclaimer-spacing {
  display: block;
}

.disclaimer {
  font-size: 1.75rem;
  font-family: Open Sans;
  text-align: center;
  margin-bottom: -1em;
}

.anchor-blue {
  color: #029cd7 !important;
}

.carousel-image {
  max-height: 75rem;
  max-width: 50rem;
}

.carousel-leader-image {
  max-height: 50rem;
  max-width: 25rem;
}

.project-description {
  margin-top: 1rem;
}

.contain {
  display: relative;
  align-items: center;
  justify-content: center;
}

.winner-circles {
  position: static;
  display: inline-block;
  padding: 10px;
}

.project-winners {
  font-size: 2.5rem;
  background-color: #1c1c1e !important;
  color: white;
  padding-top: 7px;
  padding-bottom: 10px;
  margin-top: 1rem;
}

.project-winners-place {
  font-size: 1.2rem;
  color: white;
  margin-top: 20px;
  padding-top: 7px;
  border-bottom: 5px solid #1e81b0;
}

.project-winners-name {
  font-size: 20px;
  text-align: center;
  color: white;
  width: 50%;
  margin-top: 1rem;
}

.winner1 {
  width: 200px;
  height: 150px;
  background-color: #e28743;
}
.winner2 {
  width: 200px;
  height: 150px;
  background-color: silver;
}

.project-winners-message {
  font-size: 2.2rem;
  color: white;
}

/***************** general CARD styles **********************/

.card-title,
.card-text {
  color: black !important;
}

.titlecard  {
  border: 3px solid black;
  border-radius: 4em;
  width: 50%;
  margin: auto;
  padding: 1em 0 1em;
  background-color: rgb(204, 235, 253);
}
.card-img {
  width: 27em !important;
  margin: auto !important;
  margin-top: 1em !important;
}
.archivedropdown {
  padding-right: 0px !important;
  border: 1px solid black;
  border-radius: 5px;
}

.archivedropdown .dropdown-menu {
  background-color: #1b1b35;
  border: 1px solid #7d7dac;
}

.project-tab-label {
  justify-content: center;
}

/****************** end of CARD styles ****************/

/**************general PROJECTS CAROUSEL styles******************/
.leadercarousel {
  max-height: 12em;
  max-width: 12em;
  margin: auto;
  background-color: rgb(0, 0, 0);
}
.leadercarousel .carousel-inner,
.leadercarousel img {
  max-height: 12em;
}
.leadercarousel .carousel-caption {
  position: absolute;
  top: 63%;
}
.leadercarousel h4 {
  font-size: 1em;
  background-color: rgba(46, 46, 46, 0.6);
  padding: 3px 0 3px;
  border-radius: 1em;
}
.leadercarousel .carousel-indicators {
  bottom: -18px;
}

@media only screen and (max-width: 768px) {
  .leadercarousel {
    max-height: 10em;
    max-width: 10em;
    margin: auto;
    background-color: rgb(0, 0, 0);
  }
  .leadercarousel .carousel-inner,
  .leadercarousel img {
    max-height: 10em;
    margin: auto;
  }
  .leadercarousel .carousel-caption {
    position: absolute;
    top: 55%;
  }
}
/****************** end of carousel styles ****************/

@media only screen and (max-width: 600px) {
  .project-header-container {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgb(0, 153, 255, 0.4)),
      url("./header-potrait-background-image.png") center center;
  }
  #project-header-title {
    position: absolute;
    z-index: 1;
    font-size: 2rem;
    top: 30%;
    left: 10%;
  }

  .project-card {
    margin-left: -25px;
    text-align: left;
    color: white;
    padding-left: 20px;
    padding-top: 30px;
  }

  .project-tab-content {
    width: 400px;
    background-image: linear-gradient(#333030, #141415);
    margin-top: -10px;
    border-radius: 3rem;
    padding-top: 15px;
  }

  .disclaimer-body {
    padding-right: 5px;
  }
  .flex-column {
    padding-bottom: 35px;
  }
  .project-title-text {
    padding-top: 20vh;
  }
  .project-poster {
    min-width: 90vw;
    padding-left: 0px;
    padding-right: 0px;
  }
  .project-description {
    margin-top: 1rem;
    margin-left: -20px;
  }
  .project-tab-label {
    font-size: 0.85rem;
  }
  .videoWrapper {
    position: relative;
    padding-left: 10%;
    padding-right: 10%;
  }
  iframe[src*="youtube"] {
    min-width: 90vw;
  }
}
