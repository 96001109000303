.modal-container {
    overflow-y: auto;
}

.event-modal-paragraph {
    margin: 0;
    font-size: .9rem;
    margin-bottom: .22rem;
    margin-left: .4rem;
    cursor: pointer;
}

.event-modal-paragraph:hover {
    color: rgb(0, 132, 255);
}

.events-modal-header {
    background-color: black;
    color: white;
    border-bottom: 1px solid rgb(92, 92, 92);

    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.events-modal-header button {
    color: white;
}

.events-modal-footer {
    background-color: black;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    border-top: 1px solid rgb(92, 92, 92);
    
}

.events-modal-container {
    
    background-color: rgb(0, 0, 0, .6);
}

.events-modal-card {
    background-color: black;
    color: white;
}

.event-title{
    padding-top: 10px;
}

a{
	color: white ;
}
@media only screen and (max-width: 420px){
    .event-modal-paragraph {
        /* font-size: .7rem; */
        /* Decreased font size to fit text into cell */
        font-size: 3vw;
    }
}