.container{
  color: white;
  margin: 0px;
  width: inherit;
}

.bottom-container{
  height: auto;
  background-color: #161616;
  font-family: 'Open Sans', sans-serif !important;
  text-align: center;
}

.top-container{
  font-family: 'Open Sans', sans-serif !important;
  text-align: left;
}

.footer-text
{
  padding: 1%;
}

.footer-leader-text{
  text-align: left;
  padding-left: 5vw;
}

.copy-right{
  color: #a5a5a5 !important;
  text-transform: uppercase;
  font-size: 10px;
}

.footer-catchy-text{
  font-size: 30px;
}

.inner{
  margin-left: 10px;
}

.iconHover{
  display: inline-table;
  cursor: pointer;
  border-radius: 10px;
}

.iconHover:hover{
  background: #636363; 
  width: 4%;
  padding-top: 3px;
  padding-bottom: 3px;
}