.sponsor-header-container {
  height: 80vh;
  color: white;
  font-weight: bold;
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgb(0, 153, 255, 0.4)),
    url("./images/professional-background.jpg") center center;
  background-size: cover !important;
}
#sponsor-header-title {
  position: absolute;
  z-index: 1;
  font-size: 5rem;
  top: 30%;
  left: 10%;
}

.jumbotron {
  color: white;
  padding-left: -3rem;
  margin-bottom: 0 !important;
  background-color: #1f1f1f !important;
}

.sponsors-header {
  padding: 0;
  font-size: 40px;
  text-align: center;
  color: rgb(255, 255, 255);
}

.sponsors-subheader {
  margin-top: -20px;
  font-size: 25px;
  text-align: center;
  color: #01a3fe;
}

.podium-tier {
  padding: 30px 22vw 65px 22vw;
  text-align: center;
  margin-left: -4em;
}

#gold-rectangle {
  background-color: #847423;
  width: 8vw;
  min-width: 62px;
  height: 380px;
}

#silver-rectangle {
  background-color: #686868;
  width: 8vw;
  min-width: 62px;
  height: 190px;
  margin: 190px 0 0 -1.91em;
}

#bronze-rectangle {
  background-color: #715028;
  width: 8vw;
  min-width: 62px;
  height: 95px;
  margin: 285px 0 0 -1.94em;
}

.benefit-group {
  min-width: 186px;
  list-style-type: none;
  border-radius: 1em;
  padding: 0;
}

.benefit-group-item {
  text-align: center;
  font-size: 15px;
  background-color: #494949;
  border-color: #1f1f1f;
  border: 2.5px solid #1f1f1f;
  padding: 10px 0;
}

.podium-body {
  margin: auto;
  justify-content: center;
}

.bottom-section {
  background-color: #282a2e;
}

.file-header {
  margin: 0.5em;
  font-size: 2rem;
  padding: 1em;
}

.file-container {
  padding-bottom: 3em;
}

.sponsor-file {
  width: 76vw;
  height: 90vh;
}
.link {
  font-size: 1.75rem;
  color: #01a3fe;
  text-decoration: underline;
}

.link:hover {
  color: white;
}
@media only screen and (max-width: 767px) {
  .podium-tier {
    padding: 0 10vw;
  }

  #gold-rectangle {
    width: 13vw;
  }

  #silver-rectangle {
    width: 13vw;
    margin-left: -1.94em;
  }

  #bronze-rectangle {
    width: 13vw;
    margin-left: -1.9em;
  }

  .sponsor-header-container {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgb(0, 153, 255, 0.4)),
      url("./images/professional-background.jpg") right center;
  }
  #sponsor-header-title {
    position: absolute;
    z-index: 1;
    font-size: 3.5rem;
    top: 30%;
    left: 10%;
  }
}

@media only screen and (max-width: 963px) {
  #gold-rectangle {
    height: 402.5px;
  }

  #silver-rectangle {
    height: 190px;
    margin-top: 212.5px;
  }

  #bronze-rectangle {
    height: 95px;
    margin-top: 307.5px;
  }
}

@media only screen and (max-width: 575.5px) {
  .podium-tier {
    padding: 0 6.3vw;
  }
}

@media only screen and (max-width: 468px) {
  #gold-rectangle {
    height: 425px;
  }

  #silver-rectangle {
    height: 212.5px;
    margin-top: 212.5px;
  }

  #bronze-rectangle {
    height: 117.5px;
    margin-top: 307.5px;
  }
}

@media only screen and (max-width: 439px) {
  #gold-rectangle {
    height: 447.5px;
  }

  #silver-rectangle {
    height: 235px;
    margin-top: 212.5px;
  }

  #bronze-rectangle {
    height: 117.5px;
    margin-top: 330px;
  }
}

@media only screen and (max-width: 436px) {
  #gold-rectangle {
    height: 470px;
  }

  #silver-rectangle {
    height: 235px;
    margin-top: 235px;
  }

  #bronze-rectangle {
    height: 117.5px;
    margin-top: 352.5px;
  }

  .podium-row {
    justify-content: left;
  }

  .podium-tier {
    padding: 0 30vw;
  }
}
