.contact-us-page {
  padding-top: 70px;
}

.txtb {
  width: auto;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 1em;
  padding-left: 1em;
  background-color: #32617b;
  border-radius: 1em;
  margin: 0.5em;
  text-align: left;
}
.txtb label {
  display: block;
  text-align: left;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
}

.txtb input,
.txtb textarea {
  width: 100%;
  border: none;
  background-color: #32617b;
  outline: none;
  font-size: 18px;
  margin-top: 6px;
  color: rgb(223, 221, 221);
}

#btn-form {
  display: block;
  background: #6f613a;
  padding: 15px 0;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  margin: auto;
  margin-top: 2em;
  margin-bottom: 3em;
  width: 81%;
  border: none;
}

.contact-us-page {
  background-color: #131d29;
}

.contact-us-block {
  width: 90%;
  font-weight: bold;
  color: white;
}

.contact-header-container {
  height: 80vh;
  color: white;
  font-weight: bold;
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgb(0, 153, 255, 0.4)),
    url("./images/header-background.jpg") center center;
  background-size: cover !important;
}

#contact-header-title {
  position: absolute;
  z-index: 1;
  font-size: 2.5rem;
  top: 30%;
  left: 10%;
}

.faq-block {
  width: 90%;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 6%;
  padding-left: 6%;
  background-color: #131d29;
}

.contact-label {
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: 1px;
}

.contact-us-card {
  width: auto;
  height: auto;
  /*padding: 5px 150px 5px 150px; */
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 6%;
  padding-left: 6%;
  border: none;
  font-weight: normal;
}

.faq-question {
  margin: 1em;
  /* padding: 3em; */
}
/* .contact-us-block {
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: black;
} */

.txtb:focus {
  background-color: #026e9f;
}
.faq-ans {
  background-color: #026e9f;
}

.label {
  color: white;
}

.card-bg {
  color: white !important;
}

@media screen and (max-width: 800px) {
  .contact-header-container {
    height: 80vh;
    color: white;
    font-weight: bold;
    position: relative;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgb(0, 153, 255, 0.4)),
      url("./images/header-background-portrait-image.png") left center;
    background-size: cover !important;
  }

  #contact-header-title {
    font-size: 3rem;
  }
}
