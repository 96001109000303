.contact-us-page {
  padding-top: 70px;
}

.txtb {
  width: auto;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 1em;
  padding-left: 1em;
  background-color: #32617b;
  border-radius: 1em;
  margin: 1em;
  text-align: left;
}

.txtb label {
  display: block;
  text-align: left;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
}

.txtb input,
.txtb textarea {
  width: 100%;
  border: none;
  background-color: #32617b;
  outline: none;
  font-size: 18px;
  margin-top: 6px;
  color: rgb(223, 221, 221);
}

#btn-form {
  display: block;
  background: #6f613a;
  padding: 15px 0;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  margin: auto;
  margin-top: 2em;
  margin-bottom: 3em;
  width: 81%;
  border: none;
}

.contact-us-page {
  background-color: #131d29;
}

.contact-us-block {
  width: 90%;
  font-weight: bold;
  color: whi  te;
}

.faq-header-container {
  height: 80vh;
  color: white;
  font-weight: bold;
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgb(0, 153, 255, 0.4)),
    url("./images/faq-bg.jpg") center top 50vw;
  background-size: cover !important;
}

#faq-header-title {
  position: absolute;
  z-index: 1;
  font-size: 2.5rem;
  top: 25%;
  left: 10%;
}

.faq-block {
  width: 90%;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 6%;
  padding-left: 6%;
  background-color: #131d29;
}

.contact-label {
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: 1px;
}

.contact-us-card {
  width: auto;
  height: auto;

  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 6%;
  padding-left: 6%;
  border: none;
  font-weight: normal;
}

.faq-question {
  margin: 1em;
}

.txtb:focus {
  background-color: #026e9f;
}
.faq-ans {
  background-color: #026e9f;
}

.label {
  color: white;
}

.card-bg {
  color: white !important;
}

.contact-us-logo {
  max-height: 250px;
  max-width: 250px;
  display: flexbox;
  margin-left: auto;
  margin-right: auto;
}

.contact-us-logo-2 {
  max-height: 60px;
  max-width: 60px;
  display: flexbox;
  margin-left: auto;
  margin-right: 15px;
}

.contact-info {
  font-size: 20px;
}

.contact-us-btn {
  align-items: center;
}

@media screen and (max-width: 800px) {
  .faq-header-container {
    height: 80vh;
    color: white;
    font-weight: bold;
    position: relative;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgb(0, 153, 255, 0.4)),
      url("./images/header-background-portrait-image.png") center top;
    background-size: cover !important;
  }

  #faq-header-title {
    position: absolute;
    z-index: 1;
    font-size: 3.5rem;
    top: 30%;
    left: 10%;
  }
}
