.ModContainer {
    width: 50%;
    margin:auto;
    padding: 10vw 0;
    display: flex;
    gap: 50px;
    flex-direction: column;

    
    

}