.events-header-container {
  height: 80vh;
  color: white;
  font-weight: bold;
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgb(0, 153, 255, 0.4)),
    url("./images/classroom-background.jpg") center top;
  background-size: cover !important;
}

#events-header-title {
  position: absolute;
  z-index: 1;
  font-size: 3.5rem;
  top: 30%;
  left: 10%;
}

#events-blur-effect {
  display: none;
}

.events-container {
  background-color: black !important;
}

.events-main-text {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
  font-size: 3.5rem;
  color: white;
  font-weight: bold;
  line-height: 1.2;

  display: grid;
  grid-template-rows: 1fr 1.4fr;
  padding-left: 3rem;
  padding-top: 70px;
}

.events-main-text p:first-of-type {
  grid-column: 1/2;
  grid-row: 1/2;
  align-self: end;
}

.events-main-text p:nth-of-type(2) {
  grid-row: 2/3;
  grid-column: 1/2;
  align-self: start;
  margin-bottom: 4rem;
}

.professional-card{
  width: auto;
	height: auto;
	background-color:#00000000 !important;
}

/* Don't delete: .jumbotron affects all Jumbotrons */
.jumbotron {
  color: white;
  padding-left: 3rem;
  margin-bottom: 0 !important;
  background-color: #1f1f1f !important;
}

.events-header {
  font-size: 2.5rem;
  margin-top: 1rem;
  margin-left: 3rem;
  color: white;
  font-weight: bold;
}

.border {
  color: #ffffff;
  background-color: #ffffff;
  height: 0.5;
  border-color: #ffffff;
  margin-left: 10px;
  margin-right: 10px;
}

.see-more-button {
  background-color: #1f1f1f;
  color: white;
  padding: 1em;
  margin-bottom: 2em;
  border-color: white;
  font-weight: bold;
}

.see-more-button:hover {
  background-color: #026e9f;
}

/* Semicolon at </Carousel>; is required. We must hide it */
#semicolon {
  color: black;
}

.images {
  display: flex;
}

.professional-slideshow {
  height: 600px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.professional-slideshow > .images {
  height: 200px;
  width: 6500px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transform: translate3d(0, 0, 0);
}
.professional-slideshow .images {
  animation: moveSlideshows 90s linear infinite;
}

@keyframes moveSlideshows {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-3070px, 0, 0);
  }
}

@media only screen and (max-width: 1004px) {
  .events-main-text {
    padding-left: 1rem;
    font-size: 2.7rem;
  }
}

@media only screen and (max-width: 420px) {
  .events-header {
    text-align: center;
    width: 100%;
    margin-left: 0 !important;
  }

  .events-header-container {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgb(0, 153, 255, 0.4)),
      url("./images/header-portrait-background-image.jpg") center top;
  }

  #events-header-title {
    position: absolute;
    z-index: 1;
    font-size: 3.5rem;
    top: 30%;
    left: 10%;
  }
}
